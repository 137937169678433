export { default as OwnableABI } from './ownableABI.json'


// 普通代币
export const STANDARDTOKEN = {
    //代币名称，简写
    //精度，数量（不用加精度个0）
    //所有权地址
    //
    constructorTypes: ['string[]', 'uint256[]', 'address[]'],
  }
  
  export const FEETOKEN = {
    constructorTypes: ['string','string','uint8','uint256','address','uint8','uint8','address','address'],
  }

  export const TOKEN314 = {
    constructorTypes: ['string','string','uint8','uint256','address','uint8','uint8','address','address','uint256[]'],
  }