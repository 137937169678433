import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    signer: ""
  },
  getters: {
  },
  mutations: {
  },
  actions: {

  },
  modules: {
    setSigner(state, value) {
      state.signer = value
    }
  }
})
