export default {
  home: {
    feiyong: '费用(链接钱包看费用)：',
    moshi: '代币模式',
    qianbao: '钱包',
    quancheng: '代币全称(比如：Tesla)',
    quancheng1: '请输入全称',
    jiancheng: '代币简称(比如：Tsla)',
    jiancheng1: '请输入简称',
    jingdu: '代币精度(默认18)',
    zongliang: '供应总量(代币总数)',
    guanli: '管理员地址',
    yingxiao: '营销手续费(填写整数)',
    ranshao: '燃烧手续费(黑洞手续费)(填写整数)',
    yingxiaodizhi: '营销钱包地址',
    chuangjian: '创建代币',
    钱包: "钱包",
    biaozhun: '标准代币',
    shouxufei: '纯手续费代币',
    ERC314: 'ERC314(ERC315)',
    kaiyuan: '开源参数',
    yuandaima: '复制源代码',
    canshu: '复制构造参数',
    yagong: '鸭公社区',
    kafei: '请我喝杯咖啡',
    zhichi: '由鸭公社区提供技术支持',
    fangqi:'放弃所有权',
    queding:'确定',
    admdizhi:'请输入管理员地址',
    daibidizhi:'请输入代币地址',
    liquidityAmount:'请输入流动性代币余额（池子需要添加多少代币，不要超过代币总量）',
    liquidityLock:'锁N个小时',
    removeLiq:'撤销流动性',
    holdFee:'持币分红手续费(填写整数)',
    holderCondition:'最小持币分红数量',
    everyHourBurnLiq:'每小时燃烧底池（单位：万分之）',
    MultiSender:"批量工具",
    addExtendLiquidityLock:'增加锁的时长（单位：小时）',
  },
}