export default {
    home: {
        feiyong: 'Fee:',
        moshi: 'Token Mode',
        qianbao: 'ownadress',
        quancheng: 'The full name of the token (eg: Tesla)',
        quancheng1: 'Please enter the full name',
        jiancheng: 'Token abbreviation (for example: Tsla)',
        jiancheng1: 'Please enter the abbreviation',
        jingdu: 'Token precision (default 18)',
        zongliang: 'Total supply (total number of tokens)',
        guanli: 'Admin address',
        yingxiao: 'Marketing fee(fill in an integer)',
        ranshao: 'Burning fee (black hole fee)(fill in an integer)',
        yingxiaodizhi: 'Marketing wallet address',
        chuangjian: 'Create Token',
        钱包: "Wallet",
        biaozhun: 'Standard Token',
        shouxufei: 'Pure Fee Token',
        kaiyuan: 'Open source parameters',
        yuandaima: 'Copy code',
        canshu: 'Copy constructor parameters',
        yagong: 'Duck Community',
        kafei: 'Buy me a cup of coffee',
        zhichi: 'Technical support provided by Yagong Community',
        fangqi:'Waiver of ownership',
        queding:'confirm',
        admdizhi:'Please enter the administrator address',
        daibidizhi:'    Please enter the token address',
        MultiSender:"Multi-Sender"
    },
}